import Style from './loading.style';

const Loading = () => {
  return (
    <Style.Container>
      <Style.Text>로딩 중 . . .</Style.Text>
    </Style.Container>
  );
};

export default Loading;
