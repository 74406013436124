import styled from 'styled-components';
import { theme } from '../../style/theme';

const ModalContainer = styled.div`
  width: 720px;
  padding: 34px;
  border-radius: 30px;
  background-color: #fff;
  border: 5px solid ${theme.colors.main};
`;

const ConfirmMessage = styled.p`
  font-size: 40px;
  text-align: center;
  margin: 100px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 22px;
`;

const MainButton = styled.button`
  height: 64px;
  padding: 14px 80px;
  border-radius: 28px;
  background-color: ${theme.colors.buttonMain};
  outline: none;
  border: none;
  color: #fff;
  font-size: 26px;
  line-height: 45px;
`;

const SubButton = styled(MainButton)`
  background-color: ${theme.colors.buttonSub};
`;

const style = { ModalContainer, ConfirmMessage, ButtonsContainer, MainButton, SubButton };

export default style;
