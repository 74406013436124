import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import Loading from './components/loading/loading.component';

const TopicListPage = React.lazy(() => import('./routes/topicList/topicList.component'));
const SpeakingPage = React.lazy(() => import('./routes/speaking/speaking.component'));
const SpeakPage = React.lazy(() => import('./routes/recordRTC'));

function App() {
  return (
    <div className='App'>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path='/' element={<TopicListPage />}></Route>
          <Route path='/speaking' element={<SpeakingPage />}></Route>
          {/* recordRTC example */}
          <Route path='/recordrtc' element={<SpeakPage />}></Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
