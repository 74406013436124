const removeParenthesis = (chat: string) => {
  const removeParenthesisRegex = /\([\w\s]+\)/g;
  return chat.replace(removeParenthesisRegex, '').trim();
};

const makeLineBreak = (chat: string) => {
  return chat
    .replace(/[.]+\s+/g, '.\n')
    .replace(/[?]+/g, '?\n')
    .replace(/[!]+/g, '!\n')
    .split('\n')
    .map((item, index) => <p key={index}>{item}</p>);
};

export { removeParenthesis, makeLineBreak };
