import Style from './chatBox.style';
import { ReactComponent as Speaker } from '../../assets/icons/speaker.svg';
import useSpeechToText from '../../hooks/textToSpeech';

interface PropsType {
  children: string;
  role?: string;
}

const ChatBox = ({ children, role }: PropsType) => {
  const { speakSpeech } = useSpeechToText();

  const onClickSpeaker = () => {
    speakSpeech(children);
  };

  return (
    <Style.Container>
      <p>{children}</p>
      {role !== 'user' && (
        <Style.IconContainer>
          <Speaker onClick={onClickSpeaker} />
        </Style.IconContainer>
      )}
    </Style.Container>
  );
};

export default ChatBox;
