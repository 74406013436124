import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useModal from '../../hooks/modalState';

import { modalState } from '../../store/modal';

import Style from './topicModal.style';
import { ReactComponent as Close } from '../../assets/icons/close.svg';

const TopicModal = () => {
  const navigate = useNavigate();
  const { closeModal } = useModal();

  const modalAtom = useRecoilValue(modalState);
  const { topic_id, topicImageUrl, topic, user_role_kr, assistant_role_kr, circumstance } = modalAtom.props;

  const onClickStartButton = () => {
    closeModal();
    navigate(`/speaking`, { state: { topic: modalAtom.props } });
  };

  return (
    <Style.ModalContainer>
      <Style.CloseButtonContainer>
        <Close onClick={closeModal} width={46} height={46} />
      </Style.CloseButtonContainer>
      <Style.TopicTitle>{topic}</Style.TopicTitle>

      <Style.TopicImageContainer>
        <Style.TopicImageBox>
          <Style.TopicImage src={topicImageUrl} alt='토픽 이미지' />
        </Style.TopicImageBox>
      </Style.TopicImageContainer>

      <Style.Circumstance>{circumstance}</Style.Circumstance>

      {/* <Style.RoleList>
          <li>나의 역할: {user_role_kr}</li>
          <li>상대 역할: {assistant_role_kr}</li>
        </Style.RoleList> */}

      <Style.RoleContainer>
        <div>
          <Style.RoleTitle>나의 역할</Style.RoleTitle>
          <Style.RoleBox>{user_role_kr}</Style.RoleBox>
        </div>
        <div>
          <Style.RoleTitle>상대 역할</Style.RoleTitle>
          <Style.RoleBox>{assistant_role_kr}</Style.RoleBox>
        </div>
      </Style.RoleContainer>

      <Style.StartButtonContainer>
        <Style.StartButton onClick={onClickStartButton}>시작하기</Style.StartButton>
      </Style.StartButtonContainer>
    </Style.ModalContainer>
  );
};

export default TopicModal;
