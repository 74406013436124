import { useRecoilValue } from 'recoil';
import { speechVoice } from '../store/speech';

const useTextToSpeech = () => {
  const speechVoiceAtom = useRecoilValue(speechVoice);

  const speechMessage = new SpeechSynthesisUtterance();
  const speech = window.speechSynthesis;

  const speakSpeech = (text: string) => {
    if (speech.speaking) return;

    speechMessage.text = text;
    speechMessage.voice = speechVoiceAtom;
    speechMessage.rate = 1;
    speechMessage.pitch = 1;
    speech.speak(speechMessage);
  };

  const cancelSpeech = () => {
    speech.cancel();
  };

  return { speakSpeech, cancelSpeech };
};

export default useTextToSpeech;
