import styled from 'styled-components';
import { theme } from '../../style/theme';

const ModalContainer = styled.div`
  width: 50vw;
  padding: 24px 0;
  border-radius: 30px;
  background-color: #fff;
  border: 5px solid ${theme.colors.main};
  position: relative;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const TopicTitle = styled.h1`
  font-size: 4.2rem;
  text-align: center;
  margin: 30px 0;

  @media screen and (max-width: ${theme.screenWidth.desktop}) {
    font-size: 3.6rem;
    margin: 24px 0;
  }
`;

const TopicImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TopicImageBox = styled.div`
  height: 160px;
`;

const TopicImage = styled.img`
  height: inherit;
`;

const Circumstance = styled.p`
  text-align: center;
  font-size: 3rem;
  margin: 30px 0;

  @media screen and (max-width: ${theme.screenWidth.desktop}) {
    font-size: 2.8rem;
    margin: 26px 0;
  }
`;

const RoleList = styled.ul`
  text-align: center;
  margin-bottom: 34px;
`;

const RoleContainer = styled.div`
  display: flex;
  gap: 50px;
  justify-content: center;
  margin-bottom: 50px;

  @media screen and (max-width: ${theme.screenWidth.desktop}) {
    margin-bottom: 40px;
  }
`;

const RoleTitle = styled.h3`
  text-align: center;
  font-size: 2.8rem;

  @media screen and (max-width: ${theme.screenWidth.desktop}) {
    font-size: 2.6rem;
  }
`;

const RoleBox = styled.div`
  border-radius: 35px;
  background-color: gray;
  padding: 18px 44px 14px;
  text-align: center;
  color: #fff;
  font-size: 2.8rem;

  @media screen and (max-width: ${theme.screenWidth.desktop}) {
    font-size: 2.6rem;
  }
`;

const StartButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const StartButton = styled.div`
  height: 64px;
  padding: 0 100px;
  border-radius: 28px;
  background-color: ${theme.colors.buttonMain};
  outline: none;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 3rem;
  line-height: 76px;
`;

const style = {
  ModalContainer,
  CloseButtonContainer,
  TopicImageContainer,
  TopicImageBox,
  TopicImage,
  TopicTitle,
  Circumstance,
  StartButtonContainer,
  StartButton,
  RoleList,
  RoleContainer,
  RoleBox,
  RoleTitle
};

export default style;
