import styled from 'styled-components';
import { theme } from '../../style/theme';

const Container = styled.div`
  display: flex;
`;

const CharacterImageContainer = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 100%;
  padding: 5px;
  background-color: ${theme.colors.main};
  margin-right: 16px;

  @media screen and (max-width: ${theme.screenWidth.desktop}) {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
`;

const CharacterImage = styled.img`
  width: 100%;
  height: 100%;
`;

const RoleChatContainer = styled.div`
  padding-top: 8px;
`;

const Role = styled.span`
  display: inline-block;
  font-size: 24px;
  padding: 0 0 5px 7px;

  @media screen and (max-width: ${theme.screenWidth.desktop}) {
    font-size: 20px;
  }
`;

const style = {
  Container,
  CharacterImageContainer,
  CharacterImage,
  RoleChatContainer,
  Role
};

export default style;
