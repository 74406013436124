import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    backgroundMain: '#f8f3e9',
    main: '#f7c259',
    topicClicked: '#44281e',
    buttonMain: '#ffa400',
    buttonSub: '#c4c4c4'
  },

  screenWidth: {
    desktop: '1440px'
  }
};

export { theme };
