import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.h1`
  font-size: 60px;
  color: #fff;
`;

const style = { Container, Text };

export default style;
