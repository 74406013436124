import useModal from '../../hooks/modalState';
import { useNavigate } from 'react-router-dom';

import Style from './confirmModal.style';

const ConfirmModal = () => {
  const navigate = useNavigate();
  const { closeModal } = useModal();

  const onClickQuitButton = () => {
    navigate('/');
    closeModal();
  };

  const onClickKeepButton = () => {
    closeModal();
  };

  return (
    <Style.ModalContainer>
      <Style.ConfirmMessage>롤플레잉을 종료하시겠습니까?</Style.ConfirmMessage>
      <Style.ButtonsContainer>
        <Style.SubButton onClick={onClickQuitButton}>종료하기</Style.SubButton>
        <Style.MainButton onClick={onClickKeepButton}>계속하기</Style.MainButton>
      </Style.ButtonsContainer>
    </Style.ModalContainer>
  );
};

export default ConfirmModal;
