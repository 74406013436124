import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import useModal from '../../hooks/modalState';
import useSpeechToText from '../../hooks/textToSpeech';

import Style from './scriptModal.style';
import { ReactComponent as CloseCircle } from '../../assets/icons/close_circle.svg';

import { modalState } from '../../store/modal';
import { removeParenthesis } from '../../utils';
import { ScriptModalType } from '../../types';

import ChatBox from '../chatBox/chatBox.component';
import AIChatContainer from '../aiChatContainer/aiChatContainer.component';

const ScriptModal = () => {
  const { closeModal } = useModal();
  const { cancelSpeech } = useSpeechToText();
  const modalAtom = useRecoilValue(modalState);
  const { topic, allChats, scriptCharacterImageUrl, assistant_role_kr }: ScriptModalType = modalAtom.props;

  const formattedChats = useMemo(() => {
    return allChats.slice(1).map((chat) => {
      const { role, content } = chat;
      return {
        role,
        content: removeParenthesis(content)
      };
    });
  }, []);

  const onClickCloseButton = () => {
    cancelSpeech();
    closeModal();
  };

  return (
    <Style.ModalContainer>
      <Style.CloseButtonContainer onClick={onClickCloseButton}>
        <CloseCircle />
      </Style.CloseButtonContainer>
      <Style.Contents>
        <Style.TopicTitle>{topic}</Style.TopicTitle>
        <div>
          {formattedChats.map((chat) => {
            const { role, content } = chat;
            const AIChat = { assistant_role_kr, content, scriptCharacterImageUrl };

            if (role === 'assistant')
              return (
                <AIChatContainer key={`chat: ${role} ${content}`} {...AIChat}>
                  {content}
                </AIChatContainer>
              );

            if (role === 'user')
              return (
                <Style.UserChatContainer key={`chat: ${role} ${content}`}>
                  <ChatBox role={role}>{content}</ChatBox>
                </Style.UserChatContainer>
              );

            return <></>;
          })}
        </div>
      </Style.Contents>
    </Style.ModalContainer>
  );
};

export default ScriptModal;
