import { useSetRecoilState } from 'recoil';
import { modalState } from '../store/modal';

const useModal = () => {
  const setModalOpen = useSetRecoilState(modalState);

  const openModal = ({ type, props }: { type: string; props: any }) => {
    setModalOpen({
      type,
      props
    });
  };

  const closeModal = () => [
    setModalOpen({
      type: '',
      props: {}
    })
  ];

  return { openModal, closeModal };
};

export default useModal;
