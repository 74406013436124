import ChatBox from '../chatBox/chatBox.component';

import Style from './aiChatContainer.style';

interface PropsType {
  scriptCharacterImageUrl: string;
  assistant_role_kr: string;
  children: string;
}

const AIChatContainer = (props: PropsType) => {
  const { scriptCharacterImageUrl, assistant_role_kr, children } = props;
  return (
    <Style.Container>
      <Style.CharacterImageContainer>
        <Style.CharacterImage src={scriptCharacterImageUrl} alt='AI 캐릭터 이미지' />
      </Style.CharacterImageContainer>
      <Style.RoleChatContainer>
        <Style.Role>{assistant_role_kr}</Style.Role>
        <ChatBox>{children}</ChatBox>
      </Style.RoleChatContainer>
    </Style.Container>
  );
};

export default AIChatContainer;
