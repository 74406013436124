import styled from 'styled-components';
import { theme } from '../../style/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.colors.backgroundMain};
  border-radius: 20px;
  padding: 28px 20px 10px 20px;
  font-size: 30px;
  max-width: 530px;

  @media screen and (max-width: ${theme.screenWidth.desktop}) {
    padding: 22px 20px 10px 20px;
    font-size: 24px;
    max-width: 400px;
  }
`;

const IconContainer = styled.div`
  width: 46px;
  margin-top: 10px;

  @media screen and (max-width: ${theme.screenWidth.desktop}) {
    width: 36px;
    height: 36px;
  }
`;

const style = {
  Container,
  IconContainer
};

export default style;
