import styled from 'styled-components';
import { theme } from '../../style/theme';

const ModalContainer = styled.div`
  width: 50vw;
  height: calc(50vw * 17 / 20);

  position: relative;
  background-image: url('/images/note.png');
  background-repeat: no-repeat;
  background-size: contain;

  margin-top: 50px;
  padding: 30px 90px 85px 170px;

  @media screen and (max-width: ${theme.screenWidth.desktop}) {
    width: 60vw;
    height: calc(60vw * 17 / 20);
    margin-top: 30px;
    padding: 16px 70px 63px 120px;
  }
`;

const NoteImage = styled.img`
  height: 100%;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: -14px;
  top: 0;
  width: 60px;
  height: 60px;

  @media screen and (max-width: ${theme.screenWidth.desktop}) {
    right: -14px;
    top: 0;
    width: 52px;
    height: 52px;
  }
`;

const TopicTitle = styled.h2`
  font-size: 46px;
  padding-bottom: 30px;

  @media screen and (max-width: ${theme.screenWidth.desktop}) {
    font-size: 36px;
  }
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px 26px 0 0;
`;

const UserChatContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 30px;

  @media screen and (max-width: ${theme.screenWidth.desktop}) {
    margin: 30px 0 25px;
  }
`;

const style = {
  ModalContainer,
  NoteImage,
  CloseButtonContainer,
  TopicTitle,
  Contents,
  UserChatContainer
};

export default style;
