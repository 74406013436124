import { createPortal } from 'react-dom';
import { useRecoilValue } from 'recoil';

import Style from './modalPortal.style';

import { modalState } from '../../../store/modal';

import TopicModal from '../../topicModal/topicModal.component';
import ScriptModal from '../../scriptModal/scriptModal.component';
import ConfirmModal from '../../confirmModal/confirmModal.component';

type ModalComponentType = {
  [key: string]: () => JSX.Element;
};

const MODAL_COMPONENTS: ModalComponentType = {
  topic: TopicModal,
  script: ScriptModal,
  confirm: ConfirmModal
};

const ModalPortal = () => {
  const modalAtom = useRecoilValue(modalState);
  const { type, props } = modalAtom;

  const selectedElement = document.querySelector('#modal-root') as HTMLDivElement;
  if (selectedElement === null) return <div></div>;

  if (!type) return null;
  const Modal = MODAL_COMPONENTS[type];

  return createPortal(
    <Style.Backdrop>
      <Modal {...props} />
    </Style.Backdrop>,
    selectedElement
  );
};

export default ModalPortal;
